/* Start Variables */

/* End Variables */
/* Global Rules */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-family: 'El Messiri', sans-serif;
  font-weight: bold;
}
body {
  background-color: #2b2b36;
}

.page {
  padding: 16px;
}
.container {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* Start Page */
div.game-logo {
  margin-top: 5vh;
}
div.start {
  margin-top: 16%;
}
a.start {
  display: inline-block;
  padding: 24px 38px;
  color: white;
  background-color: #69c558;
  border: 12px solid #94e267;
  border-radius: 2px 20px 2px 20px;
  text-shadow: 3px 3px 5px black;
  text-decoration: none;
  font-weight: bold;
  font-size: 30px;
}
.company-logo {
  position: absolute;
  bottom: 30px;
}

/* Difficulty Page */
.choose-diff {
  padding: 18px 30px;
  background-color: #c55858;
  color: white;
  border: 12px solid #e26767;
  border-radius: 2px 20px 2px 20px;
  text-shadow: 3px 3px 5px black;
  font-size: 30px;
  margin-top: 5vh;
  margin-bottom: 6vh;
}
div.diff-background a {
  color: white;
  background-color: #5a5a5a;
  border: 12px solid #757575;
  border-radius: 50% 50% 50% 50%;
  text-decoration: none;
  text-align: center;
  line-height: 142px;
  display: block;
  width: 170px;
  height: 170px;
  margin-bottom: 5%;
  font-size: 22px;
}

/* Categories Page */
p.choose-category {
  padding: 12px 20px;
  background-color: #707070;
  color: white;
  border: 0px solid #707070;
  border-radius: 15px 20px 30px 30px;
  font-size: 24px;
}
div.cards-container {
  max-width: 300px;
  gap: 8px;
  /* height: 480px; */
  flex-wrap: wrap;
}
a.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border: 12px solid transparent;
  border-radius: 20px;
}
.category-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 150px; */
  height: 140px;
  /* margin: 10px; */
  border: 1px solid transparent;
  border-radius: 10px;
}
a.imam {
  background-color: #bc6a36;
  border-color: #d67c42;
}
a.lammah {
  background-color: #39908f;
  border-color: #42afae;
}
a.faheem {
  background-color: #87bdc7;
  border-color: #98dfec;
}
a.adeeb {
  background-color: #b78521;
  border-color: #cc972b;
}
a.challenge {
  background-color: #d1d1d5;
  border-color: #979797;
  width: 300px;
}
a.card img {
  width: 80px;
  height: 80px;
}

/* Imam Page */
a.back-icon {
  text-align: center;
  line-height: 50px;
  width: 50px;
  height: 50px;
  align-self: flex-start;
}
i[class='fas fa-arrow-left'],
p.question {
  color: white;
}
div.questionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* width: 90%; */
  min-height: 210px;
  background-color: #bc6a36;
  border: 12px solid #d67c42;
  border-radius: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin: 0px auto;
  overflow: hidden;
}
div.question p {
  z-index: 1;
  line-height: 24px;
}
span.visual {
  background-color: #b3b3b3;
  width: 50%;
  height: 60px;
  opacity: 0.2;
  position: absolute;
  right: 55px;
  bottom: 40px;
  transform: rotate(5deg);
  border: 1px solid transparent;
  border-radius: 70px 50px 70px 45px;
}
div.timer {
  background-color: #4e5f70;
  border: 2px solid #a4a4a4;
  border-radius: 20px;
  width: 80%;
  min-height: 125px;
  margin: auto;
  text-align: center;
  margin-top: 3vh;
  padding: 10px;
  flex-wrap: wrap;
}
i[class='far fa-clock'] {
  color: white;
  font-size: 50px;
  display: inline-block;
}
div.timer p {
  color: white;
  font-size: 55px;
  font-weight: normal;
  letter-spacing: 3px;
  margin: 0px;
  margin-left: 10px;
}
.start-time {
  background-color: transparent;
  border: 3px solid #a4a4a4;
  border-radius: 20px;
  color: white;
  padding: 16px;
  display: inline-block;
  margin: 3px;
  cursor: pointer;
  width: 100%;
}
div.answers-container {
  background-color: #4e5f70;
  border: 2px solid #a4a4a4;
  border-radius: 20px;
  /* width: 90%; */
  padding: 10px;
  margin: auto;
  margin-top: 3vh;
  justify-content: space-around;
}
div.answer {
  width: 100%;
  text-align: center;
}
a.correct,
a.wrong {
  padding: 12px 35px;
  margin: 8px 2px;
  font-size: 30px;
  border-radius: 15px;
  display: inline-block;
}
a.correct {
  border: 2px solid springgreen;
  color: springgreen;
}
a.wrong {
  border: 2px solid #e85c5c;
  color: #e85c5c;
}
div.answer a i {
  width: 30px;
  height: 30px;
}

#answer {
  color: white;
}
button.show-answer {
  width: 100%;
  height: 60px;
  background-color: transparent;
  border: 3px solid #a4a4a4;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}
button.show-answer:hover {
  background-color: #50e3c2;
}

/* @media(min-width:576px){
    .parent {
      width: 550px;
    }
  }
  @media(min-width:768px){
    .parent {
      width: 750px;
    }
  }
  @media(min-width:992px){
    .parent {
      width: 970px;
    }
  }
  @media(min-width:1200px){
    .parent {
      width: 1180px;
    }
  }
  @media(min-width:1400px){
    .parent {
      width: 1380px;
    }
  } */

button.icon-button {
  background-color: transparent;
  border: none;
  padding: 0;
}
